import { Route, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./pages/home";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <BrowserRouter>
          <Switch>
            <Route path="/" component={Home} exact />
          </Switch>
          <ToastContainer />
        </BrowserRouter>
      </React.StrictMode>
    </QueryClientProvider>
  );
}

export default App;

import React, { useState } from "react";
import classNames from "./navbar.module.scss";

//assets
import { LuLogIn } from "react-icons/lu";
import logo from "../../assets/images/global/logo.svg";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";

const Navbar = () => {
  const [mobileLinksOpen, setMobileLinksOpen] = useState(false);

  return (
    <div className={classNames.navbar}>
      <div>
        <img className={classNames.mainLogo} src={logo} alt="logo" />
      </div>
      <div>
        <div className={classNames.typedLogo}>FIN(AI)D</div>
      </div>
      <div className={classNames.btnsContainer}>
        <div className={classNames.manifestBtn}>Manifest</div>
        <div className={classNames.loginBtn}>
          <LuLogIn />
          <span>Login</span>
        </div>
      </div>
      <div
        className={classNames.linksMobile}
        // style={{ overflow: mobileLinksOpen ? "" : "hidden" }}
      >
        <div onClick={() => setMobileLinksOpen((prev) => !prev)}>
          <RxHamburgerMenu
            style={{
              opacity: mobileLinksOpen ? 0 : "",
              transform: mobileLinksOpen
                ? "translate(-50%, -50%) rotate(360deg)"
                : "",
            }}
          />
          <IoClose
            style={{
              opacity: !mobileLinksOpen ? 0 : "",
              transform: !mobileLinksOpen
                ? "translate(-50%, -50%) rotate(360deg)"
                : "",
            }}
          />
        </div>
        <div
          className={classNames.linksDropdown}
          style={{
            top: mobileLinksOpen ? "" : "-50px",
            right: mobileLinksOpen ? "" : "-50px",
            height: mobileLinksOpen ? "" : "0",
            width: mobileLinksOpen ? "" : "0",
            opacity: mobileLinksOpen ? "" : "0",
          }}
        >
          <div className={classNames.manifestBtn}>Manifest</div>
          <div className={classNames.loginBtn}>
            <LuLogIn />
            <span>Login</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

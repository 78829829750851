import React from "react";
import classNames from "./agentmarketplace.module.scss";
import { allAgents } from "../../../assets/data";

const AgentMarketplace = () => {
  return (
    <div className={classNames.agentMarketplace}>
      <div className={classNames.mainText}>AGENT MARKETPLACE</div>
      <div className={classNames.allCards}>
        {allAgents?.map((eachAgent, index) => {
          return (
            <div>
              <img src={eachAgent?.profilePic} alt="" />
              <div className={classNames.title}>{eachAgent?.name}</div>
              <div className={classNames.desc}>{eachAgent?.desc}</div>
              <div className={classNames.startBtn}>Start Now</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AgentMarketplace;
